import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    // {
    //     path: '/dashboard', title: 'Dashboard', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    // },
    {
        path: '/g-reports', title: 'Dashboard', icon: 'ft-pie-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Ses Hizmeti', icon: 'ft-activity', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
          { path: '/live', title: 'Aktif Çağrılar', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/voice/cdr', title: 'Çağrı Geçmişi', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/voice/numbers', title: 'Numaralar', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '/search', title: 'Çağrı Bulma', icon: 'ft-search', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/cdr', title: 'Arama Geçmişi', icon: 'ft-phone-call', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    // {
    //     path: '/archive', title: 'Arşiv', icon: 'ft-save', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    // },
    {
        path: '', title: 'Çağrı Raporları', icon: 'ft-trending-up', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/reports/hourly', title: 'Saatlik Çağrı Yoğunluğu', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/reports/sli', title: 'Servis Seviyesi Oranı', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    // {
    //     path: '', title: 'Zamanlayıcı', icon: 'ft-watch', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    //     submenu: [
    //         { path: 'javascript:;', title: 'Saatlik Çağrı Yoğunluğu', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //         { path: 'javascript:;', title: 'Servis Seviyesi Oranı', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     ]
    // },
    {
        path: '/server-status', title: 'Santral Durumu', icon: 'ft-monitor', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/invoices', title: 'Faturalar', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    }
];
