import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: '',
    loadChildren: './pages/cdr-reports-graphics/cdr-reports-graphics-components.module#CdrReportsGraphicsModule'
  },
  {
    path: 'voice',
    loadChildren: './pages/voice/voice-components.module#VoiceModule'
  },
  {
    path: 'live',
    loadChildren: './pages/live-calls/live-calls.module#LiveCallsModule'
  },
  {
    path: 'search',
    loadChildren: './pages/search/search.module#SearchModule'
  },
  {
    path: 'cdr',
    loadChildren: './pages/cdr/cdr.module#CdrModule'
  },
  {
    path: 'reports',
    loadChildren: './pages/cdr-reports/cdr-reports-components.module#CdrReportsModule'
  },
  {
    path: 'g-reports',
    loadChildren: './pages/cdr-reports-graphics/cdr-reports-graphics-components.module#CdrReportsGraphicsModule'
  },
  {
    path: 'server-status',
    loadChildren: './pages/server-status/server-status-components.module#ServerStatusModule'
  },
  {
    path: 'invoices',
    loadChildren: './pages/invoices/invoices-components.module#InvoicesModule'
  },
  {
    path: 'profile',
    loadChildren: './pages/profile/profile-components.module#ProfileModule'
  }
];
