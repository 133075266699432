import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Response } from '@angular/http';

@Injectable()
export class AuthService {
  token: string;

  constructor(private router: Router) {}

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    return Observable.fromPromise(new Promise((resolve, reject) => {
      var URL = "https://csportalapi.kobikom.com.tr/api/login";
      var params = "email=" + email +"&password=" + password + "";

      const req = new XMLHttpRequest();
      req.open( "POST", URL, false );
      req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

      req.onreadystatechange = function() {//Call a function when the state changes.
        if( req.status == 200) {
          var response = JSON.parse( req.response );
          if ( response.status == "success" ) {
            localStorage.setItem( 'currentUser', response.token );
            //localStorage.getItem( 'currentUser' );
            resolve( response );
          } else {
            reject( response );
          }
        } else {
          reject( req.response );
        }
      }
      req.send(params);
    }));
  }

  logout() {
    localStorage.removeItem('currentUser');
  }

  getToken(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', 'https://bitaksiapi.voipsantral.com.tr/api/login');

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    req.send();
    return this.token;
  }

  isAuthenticated( roles ) {
    if ( localStorage.getItem('currentUser') ) {
      if( typeof roles[0] != "undefined" && roles[0] == "SuperAdmin" ) {

        let userType = this.parseJwt( localStorage.getItem('currentUser') );

        if ( userType.prv == "Admin" ) {
          return true;
        }

      } else {
        return true;
      }

    }
    this.router.navigateByUrl('login');
    return false;
  }

  parseJwt( token ) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };
}
